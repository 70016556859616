import { createStore as reduxCreateStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

const reducer = (state, action) => {
  if (action.type === "LOADING_START") {
    return { ...state, isLoading: true, tokenCollectionError: {} };
  }

  if (action.type === "UPDATE_ACTIVE_TOKEN") {
    return { ...state, activeToken: action.data };
  }
  if (action.type === "RESET_ACTIVE_TOKEN") {
    return { ...state, activeToken: {} };
  }
  if (action.type === "UPDATE_TOKEN_COLLECTION") {
    return { ...state, isLoading: false, tokenCollection: action.data };
  }
  if (action.type === "UPDATE_TOKEN_COLLECTION_ERROR") {
    return { ...state, isLoading: false, tokenCollectionError: action.data };
  }
  if (action.type === "UPDATE_TOKEN_DATA") {
    return { ...state, isLoading: false, tokenData: action.data };
  }
  if (action.type === "UPDATE_TOKEN_DATA_ERROR") {
    return { ...state, isLoading: false, tokenDataError: action.data };
  }

  return state;
};

const initialState = {
  isLoading: false,
  tokenCollection: [],
  tokenData: {},
  tokenCollectionError: {},
  tokenDataError: {},
  activeToken: {},

  activePageId: "1",
  productData: {
    id: "7b0c41f8-292a-46a5-a55e-006014e5a0bf",
    catid: "demodata",
    companyname: "Simple Chic",
    desc: "View the product sustainability pages for Simple Chic products.",
    display: {
      headerimage: 1,
      title: 0,
    },
    GSI1PK: "demodata",
    GSI1SK: "simplechic",
    headerimage:
      "https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/simple_chic_logo_300x.jpg",
    pages: [
      {
        id: "1",
        desc: "See sustainability info for the Simple Chic Georgia Wrap",
        events: [
          {
            category: "rawmaterials",
            content:
              "**Raw Materials.** Made from 100% Fine ZQ Merino Wool Jersey, the world’s leading sustainable, ethical & regenerative wool. ZQ is an established grower standard, owned and operated by The New Zealand Merino Company Ltd and is recognised by the ISO/IEC 17065:2012 standard. ZQ certified wool guarantees sustainably farmed, ethical, quality wool. [Learn more about ZQ Wool.](https://www.discoverzq.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/ZQFineMerinoWool_2000x.jpg)",
            daterecord: "Dec. 13, 2023 2:00 pm",
            sender: "Simple Chic",
          },
          {
            category: "rawmaterials",
            content:
              "**Raw materials manufacturing.** The Fabric Store is both the manufacturer and distributor of ZQ Premium Merino Wool. [Learn more about The Fabric Store](https://thefabricstoreonline.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-fabric.jpg)",
            daterecord: "Dec. 13, 2023 2:02 pm",
            sender: "Simple Chic",
          },
          {
            category: "design",
            content:
              "**Design.** Designed by Simple Chic's Founder in collaboration with Mantua Sewing Studio's Pattern Maker.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-design.jpg)",
            daterecord: "Dec. 13, 2023 2:05 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Made-to-Order.** Garments are made-to-order and customised for individual customers based on their colour, measurements and style preferences. They are created consciously based on customer needs, wants and zero textile waste to minimise the volume of clothing sent to landfill. It results in a better quality garment and fit - so each piece is loved and worn repeatedly over time.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-pattern.jpg)",
            daterecord: "Dec. 13, 2023 2:09 pm",
            sender: "Simple Chic",
          },
          {
            category: "manufacturing",
            content:
              "**Manufacturing.** Made in Australia by Mantua Sewing Studio. A Brisbane based Social Enterprise that provides local, ethical and high quality design and manufacturing services by drawing on the sewing skills and passions of women from refugee and migrant backgrounds. [Learn about Mantua Sewing Studio](https://simplechic.com/pages/mantua-sewing-studio)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/mantua_sewing_studio.jpg)",
            daterecord: "Dec. 13, 2023 2:11 pm",
            sender: "Simple Chic",
          },
          {
            category: "packaging",
            content:
              "**Packaging.** Compostable packaging and recycled ocean bound plastic provided by Better Packing Co., a B-Corp Certified Company. [More about Better Packing Co.](https://www.au.betterpackaging.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-package.jpg)",
            daterecord: "Dec. 13, 2023 2:12 pm",
            sender: "Simple Chic",
          },
          {
            category: "shipping",
            content:
              "**Shipping.** Shipping provided by Sendle, which features 100% carbon-neutral delivery. Sendle is a B-Corp Certified Company. [More about Sendle](https://www.sendle.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-delivery.jpg)",
            daterecord: "Dec. 13, 2023 2:13 pm",
            sender: "Simple Chic",
          },
          {
            category: "careinstructions",
            content:
              "**Care Instructions.** Garment care instructions: Cold hand-wash or machine wash on wool / gentle cycle using a wool laundry liquid only. Hang to dry on a coat hanger away from direct or strong sunlight.  Gentle iron over a cotton cloth, if required. Garment is also dry cleanable. Learn more about how to care, wash and store your wool garment by the [Woolmark Company](https://www.woolmark.com/care/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-sheep.jpg)",
            daterecord: "Dec. 13, 2023 2:16 pm",
            sender: "Simple Chic",
          },
          {
            category: "repairability",
            content:
              "**Repair & Upcycling** Simple Chic provides an alterations, repairs and up-cycling service post sale. This service is also through our community of local women-led independent micro-businesses. [Learn More](https://simplechic.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-sewing.jpg)",
            daterecord: "Dec. 13, 2023 2:18 pm",
            sender: "Simple Chic",
          },
          {
            category: "reuse",
            content:
              "**Re-sale.** AirRobe is Simple Chic’s Online Re-Sale partner. [Visit AirRobe](https://www.airrobe.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-package.jpg)",
            daterecord: "Dec. 13, 2023 2:20 pm",
            sender: "Simple Chic",
          },
          {
            category: "recycling",
            content:
              "**Recycling.** Upparel is Simple Chic’s recycling partner. [Visit Upparel](https://upparel.com.au/simple-chic/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-recycle.jpg)",
            daterecord: "Dec. 13, 2023 2:21 pm",
            sender: "Simple Chic",
          },
          {
            category: "carbonfootprint",
            content:
              "**Carbon Neutral.** Simple Chic partners with Trace to be a Carbon Neutral organisation. We measure the carbon emissions and offset those emissions through certified carbon credits and tree planting. [View our progress](https://simplechic.com/pages/carbon-neutral)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/trace.jpg)",
            daterecord: "Dec. 13, 2023 2:24 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Supplier Code-of-Conduct.** Our [Supply Chain Code of Conduct](https://simplechic.com/pages/code-of-conduct) aims to define the standards and responsibilities across our supply chain.",
            daterecord: "Dec. 13, 2023 2:26 pm",
            sender: "Simple Chic",
          },
          {
            category: "certifications",
            content:
              "**B-Corp Application.** Simple Chic have completed the BIA assessment and applied to become a Certified B-Corporation. We are currently awaiting for our review with the B-Corp Assessment Team.",
            daterecord: "Dec. 13, 2023 2:28 pm",
            sender: "Simple Chic",
          },
          {
            category: "certifications",
            content:
              "**Green Small Business.** Green Small Business has provided independent verification that we have made a clear commitment to improving the management of all our significant environmental impacts and have put in place a policy and an action plan to deliver that commitment. Progress against our action plan will be reviewed annually.\n\nGreen Small Business is based on similar principles to ISO 14001 but allows small businesses to gain independent environmental certification in a way that is proportionate to the size of their business. [Learn about Green Small Business](https://greensmallbusiness.com/case-studies/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/greensmallbusiness.jpg)",
            daterecord: "Dec. 13, 2023 2:29 pm",
            sender: "Simple Chic",
          },
          {
            category: "companydetails",
            content:
              "**Company Details.** Simple Chic's mission is to\n\n1. Build a holistic Circular Clothing System which is, restorative & regenerative, and creates positive social, environmental and economic impact.\n\n2. Inspire, educate & enable women to easily access custom clothing services & natural fabrics to fulfil their physical, wellness & self-esteem needs.\n\n3. Elevate local Women-Led Designers and Makers of women's clothing by increasing their visibility, economic opportunities and enduring growth.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/SCW_Desktop_Logo_410x.jpg)",
            daterecord: "Dec. 13, 2023 2:31 pm",
            sender: "Simple Chic",
          },
          {
            category: "unsdg",
            content:
              "**U.N. Sustainable Development Goals.** Strategically Simple Chic's business is aligned to positively impact five United Nations [Sustainable Development Goals](https://sdgs.un.org/goals) (SDGs).\n\n**UN SDG 3:** Good Health and Well-Being. Ensure healthy lives and promote well-being for all at all ages.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-03.png)\n\n**UN SDG 5:** Gender Equality.Achieve gender equality and empower all women and girls.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-05.png)\n\n**UN SDG 12:** Responsible Consumption and Production. Ensure sustainable consumption and production patterns.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-12.png)\n\n**UN SDG 13:** Climate Action. Take urgent action to combat climate change and its impacts.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-13.png)\n\n**UN SDG 17:** Partnerships.Strengthen the means of implementation and revitalise the global partnership for sustainable development.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-17.png)",
            daterecord: "Dec. 13, 2023 2:35 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Unique Circular Design Tool.** Simple Chic's Circular Clothing System represents a global best practice principle of the Circular Economy. Inspired by the Ellen Macarthur Foundation, we deliberately  transition away from the current linear economy of take-make-dispose. Instead, the circular economy is based on the principles of designing out waste and pollution, keeping products and materials in use, and regenerating natural systems.\n\nSee our Three (3) Key Pillars of Impact: 1, REUSE, 2. RENEW, and 3. REDUCE here: [https://simplechic.com/pages/circularity](https://simplechic.com/pages/circularity)",
            daterecord: "Dec. 13, 2023 2:36 pm",
            sender: "Simple Chic",
          },
        ],
        poster:
          "https://piconextcustomer-prod.s3.amazonaws.com/simplechic/products/Wrap_Avacado_smaller_file-158679_2000x.jpg",
        publish: 1,
        title: "Georgia Wrap",
      },
      {
        id: "2",
        desc: "See sustainability info for the Simple Chic Circular Scarf & Belt",
        events: [
          {
            category: "rawmaterials",
            content:
              "**Raw Materials.** Made from 100% Fine ZQ Merino Wool Jersey, the world’s leading sustainable, ethical & regenerative wool. ZQ is an established grower standard, owned and operated by The New Zealand Merino Company Ltd and is recognised by the ISO/IEC 17065:2012 standard. ZQ certified wool guarantees sustainably farmed, ethical, quality wool. [Learn more about ZQ Wool.](https://www.discoverzq.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/ZQFineMerinoWool_2000x.jpg)",
            daterecord: "Dec. 13, 2023 2:00 pm",
            sender: "Simple Chic",
          },
          {
            category: "rawmaterials",
            content:
              "**Raw materials manufacturing.** The Fabric Store is both the manufacturer and distributor of ZQ Premium Merino Wool. [Learn more about The Fabric Store](https://thefabricstoreonline.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-fabric.jpg)",
            daterecord: "Dec. 13, 2023 2:02 pm",
            sender: "Simple Chic",
          },
          {
            category: "design",
            content:
              "**Design.** Designed by Simple Chic's Founder in collaboration with Mantua Sewing Studio's Pattern Maker.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-design.jpg)",
            daterecord: "Dec. 13, 2023 2:05 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Made-to-Order.** Garments are made-to-order and customised for individual customers based on their colour, measurements and style preferences. They are created consciously based on customer needs, wants and zero textile waste to minimise the volume of clothing sent to landfill. It results in a better quality garment and fit - so each piece is loved and worn repeatedly over time.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-pattern.jpg)",
            daterecord: "Dec. 13, 2023 2:09 pm",
            sender: "Simple Chic",
          },
          {
            category: "manufacturing",
            content:
              "**Manufacturing.** Made in Australia by Mantua Sewing Studio. A Brisbane based Social Enterprise that provides local, ethical and high quality design and manufacturing services by drawing on the sewing skills and passions of women from refugee and migrant backgrounds. [Learn about Mantua Sewing Studio](https://simplechic.com/pages/mantua-sewing-studio)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/mantua_sewing_studio.jpg)",
            daterecord: "Dec. 13, 2023 2:11 pm",
            sender: "Simple Chic",
          },
          {
            category: "packaging",
            content:
              "**Packaging.** Compostable packaging and recycled ocean bound plastic provided by Better Packing Co., a B-Corp Certified Company. [More about Better Packing Co.](https://www.au.betterpackaging.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-package.jpg)",
            daterecord: "Dec. 13, 2023 2:12 pm",
            sender: "Simple Chic",
          },
          {
            category: "shipping",
            content:
              "**Shipping.** Shipping provided by Sendle, which features 100% carbon-neutral delivery. Sendle is a B-Corp Certified Company. [More about Sendle](https://www.sendle.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-delivery.jpg)",
            daterecord: "Dec. 13, 2023 2:13 pm",
            sender: "Simple Chic",
          },
          {
            category: "careinstructions",
            content:
              "**Care Instructions.** Garment care instructions: Cold hand-wash or machine wash on wool / gentle cycle using a wool laundry liquid only. Hang to dry on a coat hanger away from direct or strong sunlight.  Gentle iron over a cotton cloth, if required. Garment is also dry cleanable. Learn more about how to care, wash and store your wool garment by the [Woolmark Company](https://www.woolmark.com/care/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-sheep.jpg)",
            daterecord: "Dec. 13, 2023 2:16 pm",
            sender: "Simple Chic",
          },
          {
            category: "repairability",
            content:
              "**Repair & Upcycling** Simple Chic provides an alterations, repairs and up-cycling service post sale. This service is also through our community of local women-led independent micro-businesses. [Learn More](https://simplechic.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-sewing.jpg)",
            daterecord: "Dec. 13, 2023 2:18 pm",
            sender: "Simple Chic",
          },
          {
            category: "reuse",
            content:
              "**Re-sale.** AirRobe is Simple Chic’s Online Re-Sale partner. [Visit AirRobe](https://www.airrobe.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-package.jpg)",
            daterecord: "Dec. 13, 2023 2:20 pm",
            sender: "Simple Chic",
          },
          {
            category: "recycling",
            content:
              "**Recycling.** Upparel is Simple Chic’s recycling partner. [Visit Upparel](https://upparel.com.au/simple-chic/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-recycle.jpg)",
            daterecord: "Dec. 13, 2023 2:21 pm",
            sender: "Simple Chic",
          },
          {
            category: "carbonfootprint",
            content:
              "**Carbon Neutral.** Simple Chic partners with Trace to be a Carbon Neutral organisation. We measure the carbon emissions and offset those emissions through certified carbon credits and tree planting. [View our progress](https://simplechic.com/pages/carbon-neutral)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/trace.jpg)",
            daterecord: "Dec. 13, 2023 2:24 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Supplier Code-of-Conduct.** Our [Supply Chain Code of Conduct](https://simplechic.com/pages/code-of-conduct) aims to define the standards and responsibilities across our supply chain.",
            daterecord: "Dec. 13, 2023 2:26 pm",
            sender: "Simple Chic",
          },
          {
            category: "certifications",
            content:
              "**B-Corp Application.** Simple Chic have completed the BIA assessment and applied to become a Certified B-Corporation. We are currently awaiting for our review with the B-Corp Assessment Team.",
            daterecord: "Dec. 13, 2023 2:28 pm",
            sender: "Simple Chic",
          },
          {
            category: "certifications",
            content:
              "**Green Small Business.** Green Small Business has provided independent verification that we have made a clear commitment to improving the management of all our significant environmental impacts and have put in place a policy and an action plan to deliver that commitment. Progress against our action plan will be reviewed annually.\n\nGreen Small Business is based on similar principles to ISO 14001 but allows small businesses to gain independent environmental certification in a way that is proportionate to the size of their business. [Learn about Green Small Business](https://greensmallbusiness.com/case-studies/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/greensmallbusiness.jpg)",
            daterecord: "Dec. 13, 2023 2:29 pm",
            sender: "Simple Chic",
          },
          {
            category: "companydetails",
            content:
              "**Company Details.** Simple Chic's mission is to\n\n1. Build a holistic Circular Clothing System which is, restorative & regenerative, and creates positive social, environmental and economic impact.\n\n2. Inspire, educate & enable women to easily access custom clothing services & natural fabrics to fulfil their physical, wellness & self-esteem needs.\n\n3. Elevate local Women-Led Designers and Makers of women's clothing by increasing their visibility, economic opportunities and enduring growth.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/SCW_Desktop_Logo_410x.jpg)",
            daterecord: "Dec. 13, 2023 2:31 pm",
            sender: "Simple Chic",
          },
          {
            category: "unsdg",
            content:
              "**U.N. Sustainable Development Goals.** Strategically Simple Chic's business is aligned to positively impact five United Nations [Sustainable Development Goals](https://sdgs.un.org/goals) (SDGs).\n\n**UN SDG 3:** Good Health and Well-Being. Ensure healthy lives and promote well-being for all at all ages.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-03.png)\n\n**UN SDG 5:** Gender Equality.Achieve gender equality and empower all women and girls.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-05.png)\n\n**UN SDG 12:** Responsible Consumption and Production. Ensure sustainable consumption and production patterns.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-12.png)\n\n**UN SDG 13:** Climate Action. Take urgent action to combat climate change and its impacts.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-13.png)\n\n**UN SDG 17:** Partnerships.Strengthen the means of implementation and revitalise the global partnership for sustainable development.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-17.png)",
            daterecord: "Dec. 13, 2023 2:35 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Unique Circular Design Tool.** Simple Chic's Circular Clothing System represents a global best practice principle of the Circular Economy. Inspired by the Ellen Macarthur Foundation, we deliberately  transition away from the current linear economy of take-make-dispose. Instead, the circular economy is based on the principles of designing out waste and pollution, keeping products and materials in use, and regenerating natural systems.\n\nSee our Three (3) Key Pillars of Impact: 1, REUSE, 2. RENEW, and 3. REDUCE here: [https://simplechic.com/pages/circularity](https://simplechic.com/pages/circularity)",
            daterecord: "Dec. 13, 2023 2:36 pm",
            sender: "Simple Chic",
          },
        ],
        poster:
          "https://piconextcustomer-prod.s3.amazonaws.com/simplechic/products/CircularScarfBeltAccessoryFrenchNavyFineMerinoWool_2000x.jpg",
        publish: 1,
        title: "Circular Scarf & Belt",
      },
      {
        id: "3",
        desc: "See sustainability info for the Simple Chic Sleeveless Tee Dress",
        events: [
          {
            category: "rawmaterials",
            content:
              "**Raw Materials.** Made from 100% Fine ZQ Merino Wool Jersey, the world’s leading sustainable, ethical & regenerative wool. ZQ is an established grower standard, owned and operated by The New Zealand Merino Company Ltd and is recognised by the ISO/IEC 17065:2012 standard. ZQ certified wool guarantees sustainably farmed, ethical, quality wool. [Learn more about ZQ Wool.](https://www.discoverzq.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/ZQFineMerinoWool_2000x.jpg)",
            daterecord: "Dec. 13, 2023 2:00 pm",
            sender: "Simple Chic",
          },
          {
            category: "rawmaterials",
            content:
              "**Raw materials manufacturing.** The Fabric Store is both the manufacturer and distributor of ZQ Premium Merino Wool. [Learn more about The Fabric Store](https://thefabricstoreonline.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-fabric.jpg)",
            daterecord: "Dec. 13, 2023 2:02 pm",
            sender: "Simple Chic",
          },
          {
            category: "design",
            content:
              "**Design.** Designed by Simple Chic's Founder in collaboration with Mantua Sewing Studio's Pattern Maker.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-design.jpg)",
            daterecord: "Dec. 13, 2023 2:05 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Made-to-Order.** Garments are made-to-order and customised for individual customers based on their colour, measurements and style preferences. They are created consciously based on customer needs, wants and zero textile waste to minimise the volume of clothing sent to landfill. It results in a better quality garment and fit - so each piece is loved and worn repeatedly over time.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-pattern.jpg)",
            daterecord: "Dec. 13, 2023 2:09 pm",
            sender: "Simple Chic",
          },
          {
            category: "manufacturing",
            content:
              "**Manufacturing.** Made in Australia by Mantua Sewing Studio. A Brisbane based Social Enterprise that provides local, ethical and high quality design and manufacturing services by drawing on the sewing skills and passions of women from refugee and migrant backgrounds. [Learn about Mantua Sewing Studio](https://simplechic.com/pages/mantua-sewing-studio)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/mantua_sewing_studio.jpg)",
            daterecord: "Dec. 13, 2023 2:11 pm",
            sender: "Simple Chic",
          },
          {
            category: "packaging",
            content:
              "**Packaging.** Compostable packaging and recycled ocean bound plastic provided by Better Packing Co., a B-Corp Certified Company. [More about Better Packing Co.](https://www.au.betterpackaging.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-package.jpg)",
            daterecord: "Dec. 13, 2023 2:12 pm",
            sender: "Simple Chic",
          },
          {
            category: "shipping",
            content:
              "**Shipping.** Shipping provided by Sendle, which features 100% carbon-neutral delivery. Sendle is a B-Corp Certified Company. [More about Sendle](https://www.sendle.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-delivery.jpg)",
            daterecord: "Dec. 13, 2023 2:13 pm",
            sender: "Simple Chic",
          },
          {
            category: "careinstructions",
            content:
              "**Care Instructions.** Garment care instructions: Cold hand-wash or machine wash on wool / gentle cycle using a wool laundry liquid only. Hang to dry on a coat hanger away from direct or strong sunlight.  Gentle iron over a cotton cloth, if required. Garment is also dry cleanable. Learn more about how to care, wash and store your wool garment by the [Woolmark Company](https://www.woolmark.com/care/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-sheep.jpg)",
            daterecord: "Dec. 13, 2023 2:16 pm",
            sender: "Simple Chic",
          },
          {
            category: "repairability",
            content:
              "**Repair & Upcycling** Simple Chic provides an alterations, repairs and up-cycling service post sale. This service is also through our community of local women-led independent micro-businesses. [Learn More](https://simplechic.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-sewing.jpg)",
            daterecord: "Dec. 13, 2023 2:18 pm",
            sender: "Simple Chic",
          },
          {
            category: "reuse",
            content:
              "**Re-sale.** AirRobe is Simple Chic’s Online Re-Sale partner. [Visit AirRobe](https://www.airrobe.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-package.jpg)",
            daterecord: "Dec. 13, 2023 2:20 pm",
            sender: "Simple Chic",
          },
          {
            category: "recycling",
            content:
              "**Recycling.** Upparel is Simple Chic’s recycling partner. [Visit Upparel](https://upparel.com.au/simple-chic/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-recycle.jpg)",
            daterecord: "Dec. 13, 2023 2:21 pm",
            sender: "Simple Chic",
          },
          {
            category: "carbonfootprint",
            content:
              "**Carbon Neutral.** Simple Chic partners with Trace to be a Carbon Neutral organisation. We measure the carbon emissions and offset those emissions through certified carbon credits and tree planting. [View our progress](https://simplechic.com/pages/carbon-neutral)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/trace.jpg)",
            daterecord: "Dec. 13, 2023 2:24 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Supplier Code-of-Conduct.** Our [Supply Chain Code of Conduct](https://simplechic.com/pages/code-of-conduct) aims to define the standards and responsibilities across our supply chain.",
            daterecord: "Dec. 13, 2023 2:26 pm",
            sender: "Simple Chic",
          },
          {
            category: "certifications",
            content:
              "**B-Corp Application.** Simple Chic have completed the BIA assessment and applied to become a Certified B-Corporation. We are currently awaiting for our review with the B-Corp Assessment Team.",
            daterecord: "Dec. 13, 2023 2:28 pm",
            sender: "Simple Chic",
          },
          {
            category: "certifications",
            content:
              "**Green Small Business.** Green Small Business has provided independent verification that we have made a clear commitment to improving the management of all our significant environmental impacts and have put in place a policy and an action plan to deliver that commitment. Progress against our action plan will be reviewed annually.\n\nGreen Small Business is based on similar principles to ISO 14001 but allows small businesses to gain independent environmental certification in a way that is proportionate to the size of their business. [Learn about Green Small Business](https://greensmallbusiness.com/case-studies/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/greensmallbusiness.jpg)",
            daterecord: "Dec. 13, 2023 2:29 pm",
            sender: "Simple Chic",
          },
          {
            category: "companydetails",
            content:
              "**Company Details.** Simple Chic's mission is to\n\n1. Build a holistic Circular Clothing System which is, restorative & regenerative, and creates positive social, environmental and economic impact.\n\n2. Inspire, educate & enable women to easily access custom clothing services & natural fabrics to fulfil their physical, wellness & self-esteem needs.\n\n3. Elevate local Women-Led Designers and Makers of women's clothing by increasing their visibility, economic opportunities and enduring growth.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/SCW_Desktop_Logo_410x.jpg)",
            daterecord: "Dec. 13, 2023 2:31 pm",
            sender: "Simple Chic",
          },
          {
            category: "unsdg",
            content:
              "**U.N. Sustainable Development Goals.** Strategically Simple Chic's business is aligned to positively impact five United Nations [Sustainable Development Goals](https://sdgs.un.org/goals) (SDGs).\n\n**UN SDG 3:** Good Health and Well-Being. Ensure healthy lives and promote well-being for all at all ages.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-03.png)\n\n**UN SDG 5:** Gender Equality.Achieve gender equality and empower all women and girls.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-05.png)\n\n**UN SDG 12:** Responsible Consumption and Production. Ensure sustainable consumption and production patterns.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-12.png)\n\n**UN SDG 13:** Climate Action. Take urgent action to combat climate change and its impacts.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-13.png)\n\n**UN SDG 17:** Partnerships.Strengthen the means of implementation and revitalise the global partnership for sustainable development.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-17.png)",
            daterecord: "Dec. 13, 2023 2:35 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Unique Circular Design Tool.** Simple Chic's Circular Clothing System represents a global best practice principle of the Circular Economy. Inspired by the Ellen Macarthur Foundation, we deliberately  transition away from the current linear economy of take-make-dispose. Instead, the circular economy is based on the principles of designing out waste and pollution, keeping products and materials in use, and regenerating natural systems.\n\nSee our Three (3) Key Pillars of Impact: 1, REUSE, 2. RENEW, and 3. REDUCE here: [https://simplechic.com/pages/circularity](https://simplechic.com/pages/circularity)",
            daterecord: "Dec. 13, 2023 2:36 pm",
            sender: "Simple Chic",
          },
        ],
        poster:
          "https://piconextcustomer-prod.s3.amazonaws.com/simplechic/products/SleevelessTeeDressFrenchNavyFineMerinoWool_2000x.jpg",
        publish: 1,
        title: "Sleeveless Tee Dress",
      },
      {
        id: "4",
        desc: "See sustainability info for the Simple Chic Wrap Dress with Long Sleeves",
        events: [
          {
            category: "rawmaterials",
            content:
              "**Raw Materials.** Made from 100% Fine ZQ Merino Wool Jersey, the world’s leading sustainable, ethical & regenerative wool. ZQ is an established grower standard, owned and operated by The New Zealand Merino Company Ltd and is recognised by the ISO/IEC 17065:2012 standard. ZQ certified wool guarantees sustainably farmed, ethical, quality wool. [Learn more about ZQ Wool.](https://www.discoverzq.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/ZQFineMerinoWool_2000x.jpg)",
            daterecord: "Dec. 13, 2023 2:00 pm",
            sender: "Simple Chic",
          },
          {
            category: "rawmaterials",
            content:
              "**Raw materials manufacturing.** The Fabric Store is both the manufacturer and distributor of ZQ Premium Merino Wool. [Learn more about The Fabric Store](https://thefabricstoreonline.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-fabric.jpg)",
            daterecord: "Dec. 13, 2023 2:02 pm",
            sender: "Simple Chic",
          },
          {
            category: "design",
            content:
              "**Design.** Designed by Simple Chic's Founder in collaboration with Mantua Sewing Studio's Pattern Maker.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-design.jpg)",
            daterecord: "Dec. 13, 2023 2:05 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Made-to-Order.** Garments are made-to-order and customised for individual customers based on their colour, measurements and style preferences. They are created consciously based on customer needs, wants and zero textile waste to minimise the volume of clothing sent to landfill. It results in a better quality garment and fit - so each piece is loved and worn repeatedly over time.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-pattern.jpg)",
            daterecord: "Dec. 13, 2023 2:09 pm",
            sender: "Simple Chic",
          },
          {
            category: "manufacturing",
            content:
              "**Manufacturing.** Made in Australia by Mantua Sewing Studio. A Brisbane based Social Enterprise that provides local, ethical and high quality design and manufacturing services by drawing on the sewing skills and passions of women from refugee and migrant backgrounds. [Learn about Mantua Sewing Studio](https://simplechic.com/pages/mantua-sewing-studio)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/mantua_sewing_studio.jpg)",
            daterecord: "Dec. 13, 2023 2:11 pm",
            sender: "Simple Chic",
          },
          {
            category: "packaging",
            content:
              "**Packaging.** Compostable packaging and recycled ocean bound plastic provided by Better Packing Co., a B-Corp Certified Company. [More about Better Packing Co.](https://www.au.betterpackaging.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-package.jpg)",
            daterecord: "Dec. 13, 2023 2:12 pm",
            sender: "Simple Chic",
          },
          {
            category: "shipping",
            content:
              "**Shipping.** Shipping provided by Sendle, which features 100% carbon-neutral delivery. Sendle is a B-Corp Certified Company. [More about Sendle](https://www.sendle.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-delivery.jpg)",
            daterecord: "Dec. 13, 2023 2:13 pm",
            sender: "Simple Chic",
          },
          {
            category: "careinstructions",
            content:
              "**Care Instructions.** Garment care instructions: Cold hand-wash or machine wash on wool / gentle cycle using a wool laundry liquid only. Hang to dry on a coat hanger away from direct or strong sunlight.  Gentle iron over a cotton cloth, if required. Garment is also dry cleanable. Learn more about how to care, wash and store your wool garment by the [Woolmark Company](https://www.woolmark.com/care/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-sheep.jpg)",
            daterecord: "Dec. 13, 2023 2:16 pm",
            sender: "Simple Chic",
          },
          {
            category: "repairability",
            content:
              "**Repair & Upcycling** Simple Chic provides an alterations, repairs and up-cycling service post sale. This service is also through our community of local women-led independent micro-businesses. [Learn More](https://simplechic.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-sewing.jpg)",
            daterecord: "Dec. 13, 2023 2:18 pm",
            sender: "Simple Chic",
          },
          {
            category: "reuse",
            content:
              "**Re-sale.** AirRobe is Simple Chic’s Online Re-Sale partner. [Visit AirRobe](https://www.airrobe.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-package.jpg)",
            daterecord: "Dec. 13, 2023 2:20 pm",
            sender: "Simple Chic",
          },
          {
            category: "recycling",
            content:
              "**Recycling.** Upparel is Simple Chic’s recycling partner. [Visit Upparel](https://upparel.com.au/simple-chic/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-recycle.jpg)",
            daterecord: "Dec. 13, 2023 2:21 pm",
            sender: "Simple Chic",
          },
          {
            category: "carbonfootprint",
            content:
              "**Carbon Neutral.** Simple Chic partners with Trace to be a Carbon Neutral organisation. We measure the carbon emissions and offset those emissions through certified carbon credits and tree planting. [View our progress](https://simplechic.com/pages/carbon-neutral)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/trace.jpg)",
            daterecord: "Dec. 13, 2023 2:24 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Supplier Code-of-Conduct.** Our [Supply Chain Code of Conduct](https://simplechic.com/pages/code-of-conduct) aims to define the standards and responsibilities across our supply chain.",
            daterecord: "Dec. 13, 2023 2:26 pm",
            sender: "Simple Chic",
          },
          {
            category: "certifications",
            content:
              "**B-Corp Application.** Simple Chic have completed the BIA assessment and applied to become a Certified B-Corporation. We are currently awaiting for our review with the B-Corp Assessment Team.",
            daterecord: "Dec. 13, 2023 2:28 pm",
            sender: "Simple Chic",
          },
          {
            category: "certifications",
            content:
              "**Green Small Business.** Green Small Business has provided independent verification that we have made a clear commitment to improving the management of all our significant environmental impacts and have put in place a policy and an action plan to deliver that commitment. Progress against our action plan will be reviewed annually.\n\nGreen Small Business is based on similar principles to ISO 14001 but allows small businesses to gain independent environmental certification in a way that is proportionate to the size of their business. [Learn about Green Small Business](https://greensmallbusiness.com/case-studies/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/greensmallbusiness.jpg)",
            daterecord: "Dec. 13, 2023 2:29 pm",
            sender: "Simple Chic",
          },
          {
            category: "companydetails",
            content:
              "**Company Details.** Simple Chic's mission is to\n\n1. Build a holistic Circular Clothing System which is, restorative & regenerative, and creates positive social, environmental and economic impact.\n\n2. Inspire, educate & enable women to easily access custom clothing services & natural fabrics to fulfil their physical, wellness & self-esteem needs.\n\n3. Elevate local Women-Led Designers and Makers of women's clothing by increasing their visibility, economic opportunities and enduring growth.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/SCW_Desktop_Logo_410x.jpg)",
            daterecord: "Dec. 13, 2023 2:31 pm",
            sender: "Simple Chic",
          },
          {
            category: "unsdg",
            content:
              "**U.N. Sustainable Development Goals.** Strategically Simple Chic's business is aligned to positively impact five United Nations [Sustainable Development Goals](https://sdgs.un.org/goals) (SDGs).\n\n**UN SDG 3:** Good Health and Well-Being. Ensure healthy lives and promote well-being for all at all ages.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-03.png)\n\n**UN SDG 5:** Gender Equality.Achieve gender equality and empower all women and girls.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-05.png)\n\n**UN SDG 12:** Responsible Consumption and Production. Ensure sustainable consumption and production patterns.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-12.png)\n\n**UN SDG 13:** Climate Action. Take urgent action to combat climate change and its impacts.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-13.png)\n\n**UN SDG 17:** Partnerships.Strengthen the means of implementation and revitalise the global partnership for sustainable development.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-17.png)",
            daterecord: "Dec. 13, 2023 2:35 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Unique Circular Design Tool.** Simple Chic's Circular Clothing System represents a global best practice principle of the Circular Economy. Inspired by the Ellen Macarthur Foundation, we deliberately  transition away from the current linear economy of take-make-dispose. Instead, the circular economy is based on the principles of designing out waste and pollution, keeping products and materials in use, and regenerating natural systems.\n\nSee our Three (3) Key Pillars of Impact: 1, REUSE, 2. RENEW, and 3. REDUCE here: [https://simplechic.com/pages/circularity](https://simplechic.com/pages/circularity)",
            daterecord: "Dec. 13, 2023 2:36 pm",
            sender: "Simple Chic",
          },
        ],
        poster:
          "https://piconextcustomer-prod.s3.amazonaws.com/simplechic/products/WrapDressLongSleevesPoppyRedFineMerinoWoolF_2000x.jpg",
        publish: 1,
        title: "Wrap Dress, Long Sleeves",
      },
      {
        id: "5",
        desc: "See sustainability info for the Simple Chic Ines Poncho",
        events: [
          {
            category: "rawmaterials",
            content:
              "**Raw Materials.** Made from 100% Fine ZQ Merino Wool Jersey, the world’s leading sustainable, ethical & regenerative wool. ZQ is an established grower standard, owned and operated by The New Zealand Merino Company Ltd and is recognised by the ISO/IEC 17065:2012 standard. ZQ certified wool guarantees sustainably farmed, ethical, quality wool. [Learn more about ZQ Wool.](https://www.discoverzq.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/ZQFineMerinoWool_2000x.jpg)",
            daterecord: "Dec. 13, 2023 2:00 pm",
            sender: "Simple Chic",
          },
          {
            category: "rawmaterials",
            content:
              "**Raw materials manufacturing.** The Fabric Store is both the manufacturer and distributor of ZQ Premium Merino Wool. [Learn more about The Fabric Store](https://thefabricstoreonline.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-fabric.jpg)",
            daterecord: "Dec. 13, 2023 2:02 pm",
            sender: "Simple Chic",
          },
          {
            category: "design",
            content:
              "**Design.** Designed by Simple Chic's Founder in collaboration with Mantua Sewing Studio's Pattern Maker.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-design.jpg)",
            daterecord: "Dec. 13, 2023 2:05 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Made-to-Order.** Garments are made-to-order and customised for individual customers based on their colour, measurements and style preferences. They are created consciously based on customer needs, wants and zero textile waste to minimise the volume of clothing sent to landfill. It results in a better quality garment and fit - so each piece is loved and worn repeatedly over time.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-pattern.jpg)",
            daterecord: "Dec. 13, 2023 2:09 pm",
            sender: "Simple Chic",
          },
          {
            category: "manufacturing",
            content:
              "**Manufacturing.** Made in Australia by Mantua Sewing Studio. A Brisbane based Social Enterprise that provides local, ethical and high quality design and manufacturing services by drawing on the sewing skills and passions of women from refugee and migrant backgrounds. [Learn about Mantua Sewing Studio](https://simplechic.com/pages/mantua-sewing-studio)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/mantua_sewing_studio.jpg)",
            daterecord: "Dec. 13, 2023 2:11 pm",
            sender: "Simple Chic",
          },
          {
            category: "packaging",
            content:
              "**Packaging.** Compostable packaging and recycled ocean bound plastic provided by Better Packing Co., a B-Corp Certified Company. [More about Better Packing Co.](https://www.au.betterpackaging.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-package.jpg)",
            daterecord: "Dec. 13, 2023 2:12 pm",
            sender: "Simple Chic",
          },
          {
            category: "shipping",
            content:
              "**Shipping.** Shipping provided by Sendle, which features 100% carbon-neutral delivery. Sendle is a B-Corp Certified Company. [More about Sendle](https://www.sendle.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-delivery.jpg)",
            daterecord: "Dec. 13, 2023 2:13 pm",
            sender: "Simple Chic",
          },
          {
            category: "careinstructions",
            content:
              "**Care Instructions.** Garment care instructions: Cold hand-wash or machine wash on wool / gentle cycle using a wool laundry liquid only. Hang to dry on a coat hanger away from direct or strong sunlight.  Gentle iron over a cotton cloth, if required. Garment is also dry cleanable. Learn more about how to care, wash and store your wool garment by the [Woolmark Company](https://www.woolmark.com/care/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-sheep.jpg)",
            daterecord: "Dec. 13, 2023 2:16 pm",
            sender: "Simple Chic",
          },
          {
            category: "repairability",
            content:
              "**Repair & Upcycling** Simple Chic provides an alterations, repairs and up-cycling service post sale. This service is also through our community of local women-led independent micro-businesses. [Learn More](https://simplechic.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-sewing.jpg)",
            daterecord: "Dec. 13, 2023 2:18 pm",
            sender: "Simple Chic",
          },
          {
            category: "reuse",
            content:
              "**Re-sale.** AirRobe is Simple Chic’s Online Re-Sale partner. [Visit AirRobe](https://www.airrobe.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-package.jpg)",
            daterecord: "Dec. 13, 2023 2:20 pm",
            sender: "Simple Chic",
          },
          {
            category: "recycling",
            content:
              "**Recycling.** Upparel is Simple Chic’s recycling partner. [Visit Upparel](https://upparel.com.au/simple-chic/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-recycle.jpg)",
            daterecord: "Dec. 13, 2023 2:21 pm",
            sender: "Simple Chic",
          },
          {
            category: "carbonfootprint",
            content:
              "**Carbon Neutral.** Simple Chic partners with Trace to be a Carbon Neutral organisation. We measure the carbon emissions and offset those emissions through certified carbon credits and tree planting. [View our progress](https://simplechic.com/pages/carbon-neutral)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/trace.jpg)",
            daterecord: "Dec. 13, 2023 2:24 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Supplier Code-of-Conduct.** Our [Supply Chain Code of Conduct](https://simplechic.com/pages/code-of-conduct) aims to define the standards and responsibilities across our supply chain.",
            daterecord: "Dec. 13, 2023 2:26 pm",
            sender: "Simple Chic",
          },
          {
            category: "certifications",
            content:
              "**B-Corp Application.** Simple Chic have completed the BIA assessment and applied to become a Certified B-Corporation. We are currently awaiting for our review with the B-Corp Assessment Team.",
            daterecord: "Dec. 13, 2023 2:28 pm",
            sender: "Simple Chic",
          },
          {
            category: "certifications",
            content:
              "**Green Small Business.** Green Small Business has provided independent verification that we have made a clear commitment to improving the management of all our significant environmental impacts and have put in place a policy and an action plan to deliver that commitment. Progress against our action plan will be reviewed annually.\n\nGreen Small Business is based on similar principles to ISO 14001 but allows small businesses to gain independent environmental certification in a way that is proportionate to the size of their business. [Learn about Green Small Business](https://greensmallbusiness.com/case-studies/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/greensmallbusiness.jpg)",
            daterecord: "Dec. 13, 2023 2:29 pm",
            sender: "Simple Chic",
          },
          {
            category: "companydetails",
            content:
              "**Company Details.** Simple Chic's mission is to\n\n1. Build a holistic Circular Clothing System which is, restorative & regenerative, and creates positive social, environmental and economic impact.\n\n2. Inspire, educate & enable women to easily access custom clothing services & natural fabrics to fulfil their physical, wellness & self-esteem needs.\n\n3. Elevate local Women-Led Designers and Makers of women's clothing by increasing their visibility, economic opportunities and enduring growth.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/SCW_Desktop_Logo_410x.jpg)",
            daterecord: "Dec. 13, 2023 2:31 pm",
            sender: "Simple Chic",
          },
          {
            category: "unsdg",
            content:
              "**U.N. Sustainable Development Goals.** Strategically Simple Chic's business is aligned to positively impact five United Nations [Sustainable Development Goals](https://sdgs.un.org/goals) (SDGs).\n\n**UN SDG 3:** Good Health and Well-Being. Ensure healthy lives and promote well-being for all at all ages.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-03.png)\n\n**UN SDG 5:** Gender Equality.Achieve gender equality and empower all women and girls.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-05.png)\n\n**UN SDG 12:** Responsible Consumption and Production. Ensure sustainable consumption and production patterns.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-12.png)\n\n**UN SDG 13:** Climate Action. Take urgent action to combat climate change and its impacts.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-13.png)\n\n**UN SDG 17:** Partnerships.Strengthen the means of implementation and revitalise the global partnership for sustainable development.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-17.png)",
            daterecord: "Dec. 13, 2023 2:35 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Unique Circular Design Tool.** Simple Chic's Circular Clothing System represents a global best practice principle of the Circular Economy. Inspired by the Ellen Macarthur Foundation, we deliberately  transition away from the current linear economy of take-make-dispose. Instead, the circular economy is based on the principles of designing out waste and pollution, keeping products and materials in use, and regenerating natural systems.\n\nSee our Three (3) Key Pillars of Impact: 1, REUSE, 2. RENEW, and 3. REDUCE here: [https://simplechic.com/pages/circularity](https://simplechic.com/pages/circularity)",
            daterecord: "Dec. 13, 2023 2:36 pm",
            sender: "Simple Chic",
          },
        ],
        poster:
          "https://piconextcustomer-prod.s3.amazonaws.com/simplechic/products/InesPoncho.jpg",
        publish: 1,
        title: "Ines Poncho",
      },
      {
        id: "6",
        desc: "See sustainability info for the Simple Chic Wrap Dress, Butterfly Cap Sleeves",
        events: [
          {
            category: "rawmaterials",
            content:
              "**Raw Materials.** Made from 100% Fine ZQ Merino Wool Jersey, the world’s leading sustainable, ethical & regenerative wool. ZQ is an established grower standard, owned and operated by The New Zealand Merino Company Ltd and is recognised by the ISO/IEC 17065:2012 standard. ZQ certified wool guarantees sustainably farmed, ethical, quality wool. [Learn more about ZQ Wool.](https://www.discoverzq.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/ZQFineMerinoWool_2000x.jpg)",
            daterecord: "Dec. 13, 2023 2:00 pm",
            sender: "Simple Chic",
          },
          {
            category: "rawmaterials",
            content:
              "**Raw materials manufacturing.** The Fabric Store is both the manufacturer and distributor of ZQ Premium Merino Wool. [Learn more about The Fabric Store](https://thefabricstoreonline.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-fabric.jpg)",
            daterecord: "Dec. 13, 2023 2:02 pm",
            sender: "Simple Chic",
          },
          {
            category: "design",
            content:
              "**Design.** Designed by Simple Chic's Founder in collaboration with Mantua Sewing Studio's Pattern Maker.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-design.jpg)",
            daterecord: "Dec. 13, 2023 2:05 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Made-to-Order.** Garments are made-to-order and customised for individual customers based on their colour, measurements and style preferences. They are created consciously based on customer needs, wants and zero textile waste to minimise the volume of clothing sent to landfill. It results in a better quality garment and fit - so each piece is loved and worn repeatedly over time.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-pattern.jpg)",
            daterecord: "Dec. 13, 2023 2:09 pm",
            sender: "Simple Chic",
          },
          {
            category: "manufacturing",
            content:
              "**Manufacturing.** Made in Australia by Mantua Sewing Studio. A Brisbane based Social Enterprise that provides local, ethical and high quality design and manufacturing services by drawing on the sewing skills and passions of women from refugee and migrant backgrounds. [Learn about Mantua Sewing Studio](https://simplechic.com/pages/mantua-sewing-studio)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/mantua_sewing_studio.jpg)",
            daterecord: "Dec. 13, 2023 2:11 pm",
            sender: "Simple Chic",
          },
          {
            category: "packaging",
            content:
              "**Packaging.** Compostable packaging and recycled ocean bound plastic provided by Better Packing Co., a B-Corp Certified Company. [More about Better Packing Co.](https://www.au.betterpackaging.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-package.jpg)",
            daterecord: "Dec. 13, 2023 2:12 pm",
            sender: "Simple Chic",
          },
          {
            category: "shipping",
            content:
              "**Shipping.** Shipping provided by Sendle, which features 100% carbon-neutral delivery. Sendle is a B-Corp Certified Company. [More about Sendle](https://www.sendle.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-delivery.jpg)",
            daterecord: "Dec. 13, 2023 2:13 pm",
            sender: "Simple Chic",
          },
          {
            category: "careinstructions",
            content:
              "**Care Instructions.** Garment care instructions: Cold hand-wash or machine wash on wool / gentle cycle using a wool laundry liquid only. Hang to dry on a coat hanger away from direct or strong sunlight.  Gentle iron over a cotton cloth, if required. Garment is also dry cleanable. Learn more about how to care, wash and store your wool garment by the [Woolmark Company](https://www.woolmark.com/care/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-sheep.jpg)",
            daterecord: "Dec. 13, 2023 2:16 pm",
            sender: "Simple Chic",
          },
          {
            category: "repairability",
            content:
              "**Repair & Upcycling** Simple Chic provides an alterations, repairs and up-cycling service post sale. This service is also through our community of local women-led independent micro-businesses. [Learn More](https://simplechic.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-sewing.jpg)",
            daterecord: "Dec. 13, 2023 2:18 pm",
            sender: "Simple Chic",
          },
          {
            category: "reuse",
            content:
              "**Re-sale.** AirRobe is Simple Chic’s Online Re-Sale partner. [Visit AirRobe](https://www.airrobe.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-package.jpg)",
            daterecord: "Dec. 13, 2023 2:20 pm",
            sender: "Simple Chic",
          },
          {
            category: "recycling",
            content:
              "**Recycling.** Upparel is Simple Chic’s recycling partner. [Visit Upparel](https://upparel.com.au/simple-chic/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-recycle.jpg)",
            daterecord: "Dec. 13, 2023 2:21 pm",
            sender: "Simple Chic",
          },
          {
            category: "carbonfootprint",
            content:
              "**Carbon Neutral.** Simple Chic partners with Trace to be a Carbon Neutral organisation. We measure the carbon emissions and offset those emissions through certified carbon credits and tree planting. [View our progress](https://simplechic.com/pages/carbon-neutral)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/trace.jpg)",
            daterecord: "Dec. 13, 2023 2:24 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Supplier Code-of-Conduct.** Our [Supply Chain Code of Conduct](https://simplechic.com/pages/code-of-conduct) aims to define the standards and responsibilities across our supply chain.",
            daterecord: "Dec. 13, 2023 2:26 pm",
            sender: "Simple Chic",
          },
          {
            category: "certifications",
            content:
              "**B-Corp Application.** Simple Chic have completed the BIA assessment and applied to become a Certified B-Corporation. We are currently awaiting for our review with the B-Corp Assessment Team.",
            daterecord: "Dec. 13, 2023 2:28 pm",
            sender: "Simple Chic",
          },
          {
            category: "certifications",
            content:
              "**Green Small Business.** Green Small Business has provided independent verification that we have made a clear commitment to improving the management of all our significant environmental impacts and have put in place a policy and an action plan to deliver that commitment. Progress against our action plan will be reviewed annually.\n\nGreen Small Business is based on similar principles to ISO 14001 but allows small businesses to gain independent environmental certification in a way that is proportionate to the size of their business. [Learn about Green Small Business](https://greensmallbusiness.com/case-studies/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/greensmallbusiness.jpg)",
            daterecord: "Dec. 13, 2023 2:29 pm",
            sender: "Simple Chic",
          },
          {
            category: "companydetails",
            content:
              "**Company Details.** Simple Chic's mission is to\n\n1. Build a holistic Circular Clothing System which is, restorative & regenerative, and creates positive social, environmental and economic impact.\n\n2. Inspire, educate & enable women to easily access custom clothing services & natural fabrics to fulfil their physical, wellness & self-esteem needs.\n\n3. Elevate local Women-Led Designers and Makers of women's clothing by increasing their visibility, economic opportunities and enduring growth.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/SCW_Desktop_Logo_410x.jpg)",
            daterecord: "Dec. 13, 2023 2:31 pm",
            sender: "Simple Chic",
          },
          {
            category: "unsdg",
            content:
              "**U.N. Sustainable Development Goals.** Strategically Simple Chic's business is aligned to positively impact five United Nations [Sustainable Development Goals](https://sdgs.un.org/goals) (SDGs).\n\n**UN SDG 3:** Good Health and Well-Being. Ensure healthy lives and promote well-being for all at all ages.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-03.png)\n\n**UN SDG 5:** Gender Equality.Achieve gender equality and empower all women and girls.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-05.png)\n\n**UN SDG 12:** Responsible Consumption and Production. Ensure sustainable consumption and production patterns.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-12.png)\n\n**UN SDG 13:** Climate Action. Take urgent action to combat climate change and its impacts.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-13.png)\n\n**UN SDG 17:** Partnerships.Strengthen the means of implementation and revitalise the global partnership for sustainable development.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-17.png)",
            daterecord: "Dec. 13, 2023 2:35 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Unique Circular Design Tool.** Simple Chic's Circular Clothing System represents a global best practice principle of the Circular Economy. Inspired by the Ellen Macarthur Foundation, we deliberately  transition away from the current linear economy of take-make-dispose. Instead, the circular economy is based on the principles of designing out waste and pollution, keeping products and materials in use, and regenerating natural systems.\n\nSee our Three (3) Key Pillars of Impact: 1, REUSE, 2. RENEW, and 3. REDUCE here: [https://simplechic.com/pages/circularity](https://simplechic.com/pages/circularity)",
            daterecord: "Dec. 13, 2023 2:36 pm",
            sender: "Simple Chic",
          },
        ],
        poster:
          "https://piconextcustomer-prod.s3.amazonaws.com/simplechic/products/WrapDressButterflySleevesFrenchNavyFineMerinoWoolF_2000x.jpg",
        publish: 1,
        title: "Wrap Dress, Butterfly Cap Sleeves",
      },
      {
        id: "7",
        desc: "See sustainability info for the Simple Chic Wrap Top, Butterfly Cap Sleeves",
        events: [
          {
            category: "rawmaterials",
            content:
              "**Raw Materials.** Made from 100% Fine ZQ Merino Wool Jersey, the world’s leading sustainable, ethical & regenerative wool. ZQ is an established grower standard, owned and operated by The New Zealand Merino Company Ltd and is recognised by the ISO/IEC 17065:2012 standard. ZQ certified wool guarantees sustainably farmed, ethical, quality wool. [Learn more about ZQ Wool.](https://www.discoverzq.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/ZQFineMerinoWool_2000x.jpg)",
            daterecord: "Dec. 13, 2023 2:00 pm",
            sender: "Simple Chic",
          },
          {
            category: "rawmaterials",
            content:
              "**Raw materials manufacturing.** The Fabric Store is both the manufacturer and distributor of ZQ Premium Merino Wool. [Learn more about The Fabric Store](https://thefabricstoreonline.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-fabric.jpg)",
            daterecord: "Dec. 13, 2023 2:02 pm",
            sender: "Simple Chic",
          },
          {
            category: "design",
            content:
              "**Design.** Designed by Simple Chic's Founder in collaboration with Mantua Sewing Studio's Pattern Maker.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-design.jpg)",
            daterecord: "Dec. 13, 2023 2:05 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Made-to-Order.** Garments are made-to-order and customised for individual customers based on their colour, measurements and style preferences. They are created consciously based on customer needs, wants and zero textile waste to minimise the volume of clothing sent to landfill. It results in a better quality garment and fit - so each piece is loved and worn repeatedly over time.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-pattern.jpg)",
            daterecord: "Dec. 13, 2023 2:09 pm",
            sender: "Simple Chic",
          },
          {
            category: "manufacturing",
            content:
              "**Manufacturing.** Made in Australia by Mantua Sewing Studio. A Brisbane based Social Enterprise that provides local, ethical and high quality design and manufacturing services by drawing on the sewing skills and passions of women from refugee and migrant backgrounds. [Learn about Mantua Sewing Studio](https://simplechic.com/pages/mantua-sewing-studio)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/mantua_sewing_studio.jpg)",
            daterecord: "Dec. 13, 2023 2:11 pm",
            sender: "Simple Chic",
          },
          {
            category: "packaging",
            content:
              "**Packaging.** Compostable packaging and recycled ocean bound plastic provided by Better Packing Co., a B-Corp Certified Company. [More about Better Packing Co.](https://www.au.betterpackaging.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-package.jpg)",
            daterecord: "Dec. 13, 2023 2:12 pm",
            sender: "Simple Chic",
          },
          {
            category: "shipping",
            content:
              "**Shipping.** Shipping provided by Sendle, which features 100% carbon-neutral delivery. Sendle is a B-Corp Certified Company. [More about Sendle](https://www.sendle.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-delivery.jpg)",
            daterecord: "Dec. 13, 2023 2:13 pm",
            sender: "Simple Chic",
          },
          {
            category: "careinstructions",
            content:
              "**Care Instructions.** Garment care instructions: Cold hand-wash or machine wash on wool / gentle cycle using a wool laundry liquid only. Hang to dry on a coat hanger away from direct or strong sunlight.  Gentle iron over a cotton cloth, if required. Garment is also dry cleanable. Learn more about how to care, wash and store your wool garment by the [Woolmark Company](https://www.woolmark.com/care/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-sheep.jpg)",
            daterecord: "Dec. 13, 2023 2:16 pm",
            sender: "Simple Chic",
          },
          {
            category: "repairability",
            content:
              "**Repair & Upcycling** Simple Chic provides an alterations, repairs and up-cycling service post sale. This service is also through our community of local women-led independent micro-businesses. [Learn More](https://simplechic.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-sewing.jpg)",
            daterecord: "Dec. 13, 2023 2:18 pm",
            sender: "Simple Chic",
          },
          {
            category: "reuse",
            content:
              "**Re-sale.** AirRobe is Simple Chic’s Online Re-Sale partner. [Visit AirRobe](https://www.airrobe.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-package.jpg)",
            daterecord: "Dec. 13, 2023 2:20 pm",
            sender: "Simple Chic",
          },
          {
            category: "recycling",
            content:
              "**Recycling.** Upparel is Simple Chic’s recycling partner. [Visit Upparel](https://upparel.com.au/simple-chic/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-recycle.jpg)",
            daterecord: "Dec. 13, 2023 2:21 pm",
            sender: "Simple Chic",
          },
          {
            category: "carbonfootprint",
            content:
              "**Carbon Neutral.** Simple Chic partners with Trace to be a Carbon Neutral organisation. We measure the carbon emissions and offset those emissions through certified carbon credits and tree planting. [View our progress](https://simplechic.com/pages/carbon-neutral)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/trace.jpg)",
            daterecord: "Dec. 13, 2023 2:24 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Supplier Code-of-Conduct.** Our [Supply Chain Code of Conduct](https://simplechic.com/pages/code-of-conduct) aims to define the standards and responsibilities across our supply chain.",
            daterecord: "Dec. 13, 2023 2:26 pm",
            sender: "Simple Chic",
          },
          {
            category: "certifications",
            content:
              "**B-Corp Application.** Simple Chic have completed the BIA assessment and applied to become a Certified B-Corporation. We are currently awaiting for our review with the B-Corp Assessment Team.",
            daterecord: "Dec. 13, 2023 2:28 pm",
            sender: "Simple Chic",
          },
          {
            category: "certifications",
            content:
              "**Green Small Business.** Green Small Business has provided independent verification that we have made a clear commitment to improving the management of all our significant environmental impacts and have put in place a policy and an action plan to deliver that commitment. Progress against our action plan will be reviewed annually.\n\nGreen Small Business is based on similar principles to ISO 14001 but allows small businesses to gain independent environmental certification in a way that is proportionate to the size of their business. [Learn about Green Small Business](https://greensmallbusiness.com/case-studies/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/greensmallbusiness.jpg)",
            daterecord: "Dec. 13, 2023 2:29 pm",
            sender: "Simple Chic",
          },
          {
            category: "companydetails",
            content:
              "**Company Details.** Simple Chic's mission is to\n\n1. Build a holistic Circular Clothing System which is, restorative & regenerative, and creates positive social, environmental and economic impact.\n\n2. Inspire, educate & enable women to easily access custom clothing services & natural fabrics to fulfil their physical, wellness & self-esteem needs.\n\n3. Elevate local Women-Led Designers and Makers of women's clothing by increasing their visibility, economic opportunities and enduring growth.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/SCW_Desktop_Logo_410x.jpg)",
            daterecord: "Dec. 13, 2023 2:31 pm",
            sender: "Simple Chic",
          },
          {
            category: "unsdg",
            content:
              "**U.N. Sustainable Development Goals.** Strategically Simple Chic's business is aligned to positively impact five United Nations [Sustainable Development Goals](https://sdgs.un.org/goals) (SDGs).\n\n**UN SDG 3:** Good Health and Well-Being. Ensure healthy lives and promote well-being for all at all ages.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-03.png)\n\n**UN SDG 5:** Gender Equality.Achieve gender equality and empower all women and girls.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-05.png)\n\n**UN SDG 12:** Responsible Consumption and Production. Ensure sustainable consumption and production patterns.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-12.png)\n\n**UN SDG 13:** Climate Action. Take urgent action to combat climate change and its impacts.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-13.png)\n\n**UN SDG 17:** Partnerships.Strengthen the means of implementation and revitalise the global partnership for sustainable development.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-17.png)",
            daterecord: "Dec. 13, 2023 2:35 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Unique Circular Design Tool.** Simple Chic's Circular Clothing System represents a global best practice principle of the Circular Economy. Inspired by the Ellen Macarthur Foundation, we deliberately  transition away from the current linear economy of take-make-dispose. Instead, the circular economy is based on the principles of designing out waste and pollution, keeping products and materials in use, and regenerating natural systems.\n\nSee our Three (3) Key Pillars of Impact: 1, REUSE, 2. RENEW, and 3. REDUCE here: [https://simplechic.com/pages/circularity](https://simplechic.com/pages/circularity)",
            daterecord: "Dec. 13, 2023 2:36 pm",
            sender: "Simple Chic",
          },
        ],
        poster:
          "https://piconextcustomer-prod.s3.amazonaws.com/simplechic/products/WrapTopButterflySleevesColourChestnutinFineMerinoWool_2000x.jpg",
        publish: 1,
        title: "Wrap Top, Butterfly Cap Sleeves",
      },
      {
        id: "8",
        desc: "See sustainability info for the Simple Chic Long Scarf & Belt",
        events: [
          {
            category: "rawmaterials",
            content:
              "**Raw Materials.** Made from 100% Fine ZQ Merino Wool Jersey, the world’s leading sustainable, ethical & regenerative wool. ZQ is an established grower standard, owned and operated by The New Zealand Merino Company Ltd and is recognised by the ISO/IEC 17065:2012 standard. ZQ certified wool guarantees sustainably farmed, ethical, quality wool. [Learn more about ZQ Wool.](https://www.discoverzq.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/ZQFineMerinoWool_2000x.jpg)",
            daterecord: "Dec. 13, 2023 2:00 pm",
            sender: "Simple Chic",
          },
          {
            category: "rawmaterials",
            content:
              "**Raw materials manufacturing.** The Fabric Store is both the manufacturer and distributor of ZQ Premium Merino Wool. [Learn more about The Fabric Store](https://thefabricstoreonline.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-fabric.jpg)",
            daterecord: "Dec. 13, 2023 2:02 pm",
            sender: "Simple Chic",
          },
          {
            category: "design",
            content:
              "**Design.** Designed by Simple Chic's Founder in collaboration with Mantua Sewing Studio's Pattern Maker.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-design.jpg)",
            daterecord: "Dec. 13, 2023 2:05 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Made-to-Order.** Garments are made-to-order and customised for individual customers based on their colour, measurements and style preferences. They are created consciously based on customer needs, wants and zero textile waste to minimise the volume of clothing sent to landfill. It results in a better quality garment and fit - so each piece is loved and worn repeatedly over time.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-pattern.jpg)",
            daterecord: "Dec. 13, 2023 2:09 pm",
            sender: "Simple Chic",
          },
          {
            category: "manufacturing",
            content:
              "**Manufacturing.** Made in Australia by Mantua Sewing Studio. A Brisbane based Social Enterprise that provides local, ethical and high quality design and manufacturing services by drawing on the sewing skills and passions of women from refugee and migrant backgrounds. [Learn about Mantua Sewing Studio](https://simplechic.com/pages/mantua-sewing-studio)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/mantua_sewing_studio.jpg)",
            daterecord: "Dec. 13, 2023 2:11 pm",
            sender: "Simple Chic",
          },
          {
            category: "packaging",
            content:
              "**Packaging.** Compostable packaging and recycled ocean bound plastic provided by Better Packing Co., a B-Corp Certified Company. [More about Better Packing Co.](https://www.au.betterpackaging.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-package.jpg)",
            daterecord: "Dec. 13, 2023 2:12 pm",
            sender: "Simple Chic",
          },
          {
            category: "shipping",
            content:
              "**Shipping.** Shipping provided by Sendle, which features 100% carbon-neutral delivery. Sendle is a B-Corp Certified Company. [More about Sendle](https://www.sendle.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-delivery.jpg)",
            daterecord: "Dec. 13, 2023 2:13 pm",
            sender: "Simple Chic",
          },
          {
            category: "careinstructions",
            content:
              "**Care Instructions.** Garment care instructions: Cold hand-wash or machine wash on wool / gentle cycle using a wool laundry liquid only. Hang to dry on a coat hanger away from direct or strong sunlight.  Gentle iron over a cotton cloth, if required. Garment is also dry cleanable. Learn more about how to care, wash and store your wool garment by the [Woolmark Company](https://www.woolmark.com/care/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-sheep.jpg)",
            daterecord: "Dec. 13, 2023 2:16 pm",
            sender: "Simple Chic",
          },
          {
            category: "repairability",
            content:
              "**Repair & Upcycling** Simple Chic provides an alterations, repairs and up-cycling service post sale. This service is also through our community of local women-led independent micro-businesses. [Learn More](https://simplechic.com/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-sewing.jpg)",
            daterecord: "Dec. 13, 2023 2:18 pm",
            sender: "Simple Chic",
          },
          {
            category: "reuse",
            content:
              "**Re-sale.** AirRobe is Simple Chic’s Online Re-Sale partner. [Visit AirRobe](https://www.airrobe.com)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-package.jpg)",
            daterecord: "Dec. 13, 2023 2:20 pm",
            sender: "Simple Chic",
          },
          {
            category: "recycling",
            content:
              "**Recycling.** Upparel is Simple Chic’s recycling partner. [Visit Upparel](https://upparel.com.au/simple-chic/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/icon-recycle.jpg)",
            daterecord: "Dec. 13, 2023 2:21 pm",
            sender: "Simple Chic",
          },
          {
            category: "carbonfootprint",
            content:
              "**Carbon Neutral.** Simple Chic partners with Trace to be a Carbon Neutral organisation. We measure the carbon emissions and offset those emissions through certified carbon credits and tree planting. [View our progress](https://simplechic.com/pages/carbon-neutral)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/trace.jpg)",
            daterecord: "Dec. 13, 2023 2:24 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Supplier Code-of-Conduct.** Our [Supply Chain Code of Conduct](https://simplechic.com/pages/code-of-conduct) aims to define the standards and responsibilities across our supply chain.",
            daterecord: "Dec. 13, 2023 2:26 pm",
            sender: "Simple Chic",
          },
          {
            category: "certifications",
            content:
              "**B-Corp Application.** Simple Chic have completed the BIA assessment and applied to become a Certified B-Corporation. We are currently awaiting for our review with the B-Corp Assessment Team.",
            daterecord: "Dec. 13, 2023 2:28 pm",
            sender: "Simple Chic",
          },
          {
            category: "certifications",
            content:
              "**Green Small Business.** Green Small Business has provided independent verification that we have made a clear commitment to improving the management of all our significant environmental impacts and have put in place a policy and an action plan to deliver that commitment. Progress against our action plan will be reviewed annually.\n\nGreen Small Business is based on similar principles to ISO 14001 but allows small businesses to gain independent environmental certification in a way that is proportionate to the size of their business. [Learn about Green Small Business](https://greensmallbusiness.com/case-studies/)\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/greensmallbusiness.jpg)",
            daterecord: "Dec. 13, 2023 2:29 pm",
            sender: "Simple Chic",
          },
          {
            category: "companydetails",
            content:
              "**Company Details.** Simple Chic's mission is to\n\n1. Build a holistic Circular Clothing System which is, restorative & regenerative, and creates positive social, environmental and economic impact.\n\n2. Inspire, educate & enable women to easily access custom clothing services & natural fabrics to fulfil their physical, wellness & self-esteem needs.\n\n3. Elevate local Women-Led Designers and Makers of women's clothing by increasing their visibility, economic opportunities and enduring growth.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/simplechic/common/SCW_Desktop_Logo_410x.jpg)",
            daterecord: "Dec. 13, 2023 2:31 pm",
            sender: "Simple Chic",
          },
          {
            category: "unsdg",
            content:
              "**U.N. Sustainable Development Goals.** Strategically Simple Chic's business is aligned to positively impact five United Nations [Sustainable Development Goals](https://sdgs.un.org/goals) (SDGs).\n\n**UN SDG 3:** Good Health and Well-Being. Ensure healthy lives and promote well-being for all at all ages.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-03.png)\n\n**UN SDG 5:** Gender Equality.Achieve gender equality and empower all women and girls.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-05.png)\n\n**UN SDG 12:** Responsible Consumption and Production. Ensure sustainable consumption and production patterns.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-12.png)\n\n**UN SDG 13:** Climate Action. Take urgent action to combat climate change and its impacts.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-13.png)\n\n**UN SDG 17:** Partnerships.Strengthen the means of implementation and revitalise the global partnership for sustainable development.\n\n![](https://piconextcustomer-prod.s3.amazonaws.com/unsdg/sm/unsdg-17.png)",
            daterecord: "Dec. 13, 2023 2:35 pm",
            sender: "Simple Chic",
          },
          {
            category: "addldetails",
            content:
              "**Unique Circular Design Tool.** Simple Chic's Circular Clothing System represents a global best practice principle of the Circular Economy. Inspired by the Ellen Macarthur Foundation, we deliberately  transition away from the current linear economy of take-make-dispose. Instead, the circular economy is based on the principles of designing out waste and pollution, keeping products and materials in use, and regenerating natural systems.\n\nSee our Three (3) Key Pillars of Impact: 1, REUSE, 2. RENEW, and 3. REDUCE here: [https://simplechic.com/pages/circularity](https://simplechic.com/pages/circularity)",
            daterecord: "Dec. 13, 2023 2:36 pm",
            sender: "Simple Chic",
          },
        ],
        poster:
          "https://piconextcustomer-prod.s3.amazonaws.com/simplechic/products/LongScarfBeltFrenchNavyFineMerinoWool_2000x.jpg",
        publish: 1,
        title: "Long Scarf & Belt",
      },
    ],
    publish: 1,
    title: "Simple Chic Sustainability Information",
  },
};

const createStore = () =>
  reduxCreateStore(reducer, initialState, applyMiddleware(thunk));

export default createStore;
